<template>
  <el-container class="forget">
    <el-main>
      <div class="box t-center">
        <!-- <h1 class="m-b-30 lh-3" v-html="$t('messageBox.forgetip')"></h1>
        <el-button
          style="width: 50%"
          type="primary"
          @click="forgetFun"
          :loading="loading"
          >{{ $t("messageBox.resetbtn") }}</el-button
        > -->
        <el-form class="form-black">
          <h3 class="tc" style="color: #fff; marginbottom: 20px">
            {{ $t("pass.title") }}
          </h3>
          <el-form-item class="m-t-10">
            <el-input v-model="forgetForm.pass" type="password" autocomplete="off"
              :placeholder="$t('pass.newPass')" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="forgetForm.rePass" type="password" autocomplete="off"
              :placeholder="$t('pass.rePass')" show-password @keyup.enter="forgetFun"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="forgetFun" :loading="loading">
              {{ $t("messageBox.resetbtn") }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
    <bot />
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      forgetForm: {
        pass: "",
        rePass: "",
      },
    };
  },
  methods: {
    forgetFun() {
      if (!this.forgetForm.pass) return this.$message(this.$t('pass.p_pass'))
      if (!this.forgetForm.rePass) return this.$message(this.$t('pass.p_rePass'))
      if (this.forgetForm.rePass !== this.forgetForm.pass) return this.$message(this.$t('pass.err_rePass'))
      this.loading = true;
      this.$axios
        .post(this.$api.forgetreset, {
          token: this.$route.query.token,
          newPassword: this.forgetForm.pass,
          confirmPassword: this.forgetForm.rePass,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message("Success");
            this.loading = false;
            setTimeout(() => {
              this.$router.replace("/login");
            }, 500);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  min-height: 100vh;
  position: relative;
  background: url("~@/assets/public/bg.png") no-repeat;
  background-attachment: fixed;
  background-size: 1920px 100%;
  background-position: center center;
  z-index: 2;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(33, 34, 47, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.box {
  width: 380px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 120px;
  color: #fff;
  .box-top {
    text-align: center;
    > img {
      height: 46px;
      margin-bottom: 30px;
    }
    > span {
      display: inline-block;
      width: 100%;
      line-height: 20px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      background-color: transparent;
      border: 1px solid #4c5158;
      border-radius: 4px;
      margin-bottom: 26px;
      padding: 12px 0;
      cursor: pointer;
      &:hover {
        border-color: #7f8a97;
      }
      > img {
        height: 24px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
  .form-black {
    padding: 30px 22px 0;
    margin-bottom: 20px;
    a {
      font-size: 14px;
      font-weight: 400;
      color: #046cfc;
      text-decoration: none;
    }
  }
  > p {
    font-size: 14px;
    font-weight: 400;
    color: #6b6b6b;
    text-align: center;
    img {
      height: 11px;
      margin-right: 10px;
    }
  }
}
</style>